import EmailIcon from '@mui/icons-material/Email';
import { alpha, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { API } from '_helpers';
import Dot from '../../assets/map.png';

const Section = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 0),
  },
  position: 'relative',
}));

const Bg = styled(Image)(({ theme }) => ({
  opacity: theme.palette.mode === 'dark' ? 0.05 : 1,
}));

interface IBanner {
  slogan: string;
  title: string;
  description: string;
  media: IMedia;
  cta: IButton;
  contactUs: IButton;
}

const Banner: React.FC<IProps<IBanner>> = ({ data }) => {
  return (
    <Section>
      <Bg src={Dot} layout="fill" alt="Hero dot" />
      <Container>
        <Grid
          container
          spacing={{ xs: 4, sm: 6, md: 8 }}
          sx={{
            height: { xs: 'auto', lg: 760 },
            zIndex: 1,
            position: 'relative',
            pt: { xs: 4, lg: 8 },
          }}
          alignItems="center"
        >
          <Grid
            item
            container
            md={6}
            xs={12}
            alignItems="center"
            sx={{ mt: { xs: 2, md: 6, lg: 2 } }}
          >
            <Stack sx={{ m: 'auto' }}>
              <Typography variant="h2" color="primary" gutterBottom>
                {data.slogan}
              </Typography>
              <Typography
                variant="h1"
                component="h1"
                color="text.primary"
                gutterBottom
                sx={{ fontSize: { xs: '2.5rem', md: '3rem' } }}
              >
                {data.title}
              </Typography>
              <Typography color="text.primary" gutterBottom>
                {data.description}
              </Typography>
              <Stack
                spacing={{ xs: 1, md: 2 }}
                direction={{ xs: 'column', md: 'row' }}
                divider={<Divider orientation="vertical" flexItem />}
                sx={{ py: { xs: 4, md: 6 } }}
              >
                <Link href={`${data.cta.path}`} passHref>
                  <Button variant="contained" component="a" disableElevation>
                    {data.cta.label}
                  </Button>
                </Link>
                <Link href={`${data.contactUs.path}`} passHref>
                  <Button
                    sx={{
                      background: (theme) =>
                        theme.palette.mode === 'dark'
                          ? alpha(theme.palette.secondary.main, 0.1)
                          : theme.palette.secondary.light,
                    }}
                    color="secondary"
                    startIcon={<EmailIcon />}
                  >
                    {data.contactUs.label}
                  </Button>
                </Link>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={6} xs={12} sx={{ zIndex: 2, position: 'relative' }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Image
                alt={data.media.alternativeText}
                src={`${API}${data.media.url}`}
                width={data.media.width}
                height={data.media.height}
                priority
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default Banner;
